// Generated by Framer (41c59c7)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["QXIpi70nW"];

const serializationHash = "framer-tZo87"

const variantClassNames = {QXIpi70nW: "framer-v-1g5k054"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "QXIpi70nW", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1g5k054", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"QXIpi70nW"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><SVG background={{alt: "", fit: "fill", pixelHeight: 3268, pixelWidth: 3917, sizes: "26px", src: "https://framerusercontent.com/images/lfoo0YmX0o5nper5NJbjwwup4.png", srcSet: "https://framerusercontent.com/images/lfoo0YmX0o5nper5NJbjwwup4.png?scale-down-to=512 512w,https://framerusercontent.com/images/lfoo0YmX0o5nper5NJbjwwup4.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/lfoo0YmX0o5nper5NJbjwwup4.png?scale-down-to=2048 2048w,https://framerusercontent.com/images/lfoo0YmX0o5nper5NJbjwwup4.png 3917w"}} className={"framer-1o0lt15"} data-framer-name={"Logo"} description={"An SVG icon of the Framer Logo."} layout={"position"} layoutDependency={layoutDependency} layoutId={"FLqztE9Fe"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 26 24\"></svg>"} svgContentId={3299238748} title={"Framer Logo"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-tZo87.framer-o03j6y, .framer-tZo87 .framer-o03j6y { display: block; }", ".framer-tZo87.framer-1g5k054 { height: 24px; overflow: visible; position: relative; width: 26px; }", ".framer-tZo87 .framer-1o0lt15 { flex: none; height: 24px; left: calc(50.00000000000002% - 26px / 2); position: absolute; top: calc(50.00000000000002% - 24px / 2); width: 26px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 26
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerMrhv5K1gX: React.ComponentType<Props> = withCSS(Component, css, "framer-tZo87") as typeof Component;
export default FramerMrhv5K1gX;

FramerMrhv5K1gX.displayName = "Logo";

FramerMrhv5K1gX.defaultProps = {height: 24, width: 26};

addFonts(FramerMrhv5K1gX, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})